import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import 'bootstrap/dist/css/bootstrap.css';
import { MyProvider } from "./Context/context"
import { registerApplication, start } from "single-spa";
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
ReactDOM.render(
  <React.StrictMode>
    <MyProvider>
      <App />
    </MyProvider>

  </React.StrictMode>,
  document.getElementById('root')
);

registerApplication({
  name: "ui-library",
  app: () => window.System.import("@lexis/ui-library"),
  activeWhen: ["/"],
  //customProps: { product: "LHL-Admin", layouts: "@lexis/legislacion" }
});

window.System.import("@lexis/lhl-api").then(() => {  
})

window.System.import("@lexis/lhl-auth").then(() => {  
})

registerApplication({
  name: "lhl-home",
  app: () => window.System.import("@lexis/lhl-home"),
  activeWhen: ["/"],
  //customProps: { product: "LHL-Admin", layouts: "@lexis/legislacion" }
});

 window.System.import("@lexis/lhl-styles").then(() => {
  // Activate the layout engine once the styleguide CSS is loaded
  start();
});

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
