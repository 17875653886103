import React, { useContext } from "react";

// make a new context
export const MyContext = React.createContext();
export const useMyContext = () => useContext(MyContext);

// create the provider
export const MyProvider = (props) => {
  return <MyContext.Provider value={{}}>{props.children}</MyContext.Provider>;
};
